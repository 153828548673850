import useGet from "api/useGet";
import React, {Fragment, useState} from "react";
import PageNation from "../../../component/basic/pageNation";
import {TableSection, TableTd, TableTr} from "../../../component/basic";

const Index = (props) => {
    const [mouseOver, setMouseOver] =  useState('');
    const [page, setPage] =  useState(1);
    const [search, setSearch] =  useState('');
    const [order, setOrder] =  useState('DESC');

    const reviewApi = useGet({url:`/review?page=${page}&search=${search}&order=${order}`, loginType:"login"});

    return (
        <>
            <div className="searchMenu">
                <div className="searchLeftMenu">
                    <button onClick={() => { setOrder(order === 'DESC' ? 'ASC' : 'DESC') }}><img src="/assets/images/icon/sort.svg"/> {order === 'DESC' ? '최신순' : '오래된순'}</button>
                </div>
                <div className="searchRightMenu">
                    <div className="searchInput">
                        <img src="/assets/images/icon/search.svg"/>
                        <input type="text" placeholder="검색어를 입력하세요" onChange={(e) => {setSearch(e?.target?.value)}}/>
                    </div>
                </div>
            </div>
            <div className="paginationTable">
                <TableSection
                    headType={true}
                    headAddClass="pcContents"
                    th={[
                        {style:{minWidth:"10%",width:"10%"}, contents:`ID`},
                        {style:{minWidth:"10%",width:"10%"}, contents:`리뷰 제목`},
                        {style:{minWidth:"12%",width:"12%"}, contents:`리뷰 수신일시`},
                        {style:{minWidth:"5%",width:"5%"}, contents:``}
                    ]}
                >
                    {reviewApi?.data?.reviews?.data?.map((download, downloadKey) => (
                        <Fragment key={downloadKey}>
                            <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={() => {}} mouseEnterFunc={() => { setMouseOver(downloadKey) }} mouseLeaveFunc={() => { setMouseOver('') }}>
                                <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{download?.id}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{download?.title}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"12%", width:"12%" }}>{download?.created_at}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"5%", width:"5%" }}>{mouseOver === downloadKey && (<img src="/assets/images/icon/fileDownload.svg"/>)}</TableTd>
                            </TableTr>
                            <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => { }} mouseLeaveFunc={() => { }}>
                                <TableTd addClass="">
                                    <div className="tableLabel">ID</div>
                                    <div className="tableValue">{download?.id}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">리뷰 제목</div>
                                    <div className="tableValue">{download?.title}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">리뷰 수신일시</div>
                                    <div className="tableValue">{download?.created_at}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <a href="">Download <img src="/assets/images/icon/fileDownload.svg"/></a>
                                </TableTd>
                            </TableTr>
                        </Fragment>
                    ))}
                </TableSection>
                {reviewApi ? <PageNation perPage={5} total={reviewApi?.data?.reviews?.last_page} page={page} func={(i)=>{setPage(i)}}/> : ""}
            </div>
        </>
    );
};

export default Index;