import useGet from "api/useGet";
import React, {Fragment, useState} from "react";
import {BottomErrMsg} from "component/basic/popup";
import PageNation from "../../../component/basic/pageNation";
import {TableSection, TableTd, TableTr} from "../../../component/basic";
import moment from "moment/moment";

const Index = (props) => {
    const [mouseOver, setMouseOver] =  useState('');
    const [page, setPage] =  useState(1);
    const [search, setSearch] =  useState('');
    const [order, setOrder] =  useState('DESC');

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const downloadApi = useGet({url:`/report/download/history?page=${page}&search=${search}&order=${order}`, loginType:"login"});

    return (
        <>
            <div className="searchMenu">
                <div className="searchLeftMenu">
                    <button onClick={() => { setOrder(order === 'DESC' ? 'ASC' : 'DESC') }}><img src="/assets/images/icon/sort.svg"/> {order === 'DESC' ? '최신순' : '오래된순'}</button>
                </div>
                <div className="searchRightMenu">
                    <div className="searchInput">
                        <img src="/assets/images/icon/search.svg"/>
                        <input type="text" placeholder="검색어를 입력하세요" onChange={(e) => {setSearch(e?.target?.value)}}/>
                    </div>
                </div>
            </div>
            <div className="paginationTable">
                <TableSection
                    headType={true}
                    headAddClass="pcContents"
                    th={[
                        {style:{minWidth:"10%",width:"10%"}, contents:`ID`},
                        {style:{minWidth:"10%",width:"10%"}, contents:`리포트 유형`},
                        {style:{minWidth:"12%",width:"12%"}, contents:`리포트 다운로드 일시`},
                        {style:{minWidth:"18%",width:"18%"}, contents:`리포트 다운로드 직원`},
                        {style:{minWidth:"9%",width:"9%"}, contents:`센서 ID`},
                        {style:{minWidth:"9%",width:"9%"}, contents:`센서 유형`},
                        {style:{minWidth:"9%",width:"9%"}, contents:`센서 이름`},
                        {style:{minWidth:"5%",width:"5%"}, contents:``}
                    ]}
                >
                    {downloadApi?.data?.report_download_histories?.data?.map((download, downloadKey) => (
                        <Fragment key={downloadKey}>
                            <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={() => {}} mouseEnterFunc={() => { setMouseOver(downloadKey) }} mouseLeaveFunc={() => { setMouseOver('') }}>
                                <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{download?.id}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{download?.category}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"12%", width:"12%" }}>{download?.created_at}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"18%", width:"18%" }}>{download?.user_name}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"18%", width:"18%" }}>{download?.sensor_id}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"18%", width:"18%" }}>{download?.category_name}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"18%", width:"18%" }}>{download?.sensor_name}</TableTd>
                                <TableTd addClass="alignLeft" style={{ minWidth:"5%", width:"5%" }}>{mouseOver === downloadKey && (<img src="/assets/images/icon/fileDownload.svg"/>)}</TableTd>
                            </TableTr>
                            <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => { }} mouseLeaveFunc={() => { }}>
                                <TableTd addClass="">
                                    <div className="tableLabel">ID</div>
                                    <div className="tableValue">{download?.id}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">리포트 유형</div>
                                    <div className="tableValue">{download?.category}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">리포트 다운로드 일시</div>
                                    <div className="tableValue">{download?.created_at}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">리포트 다운로드 직원</div>
                                    <div className="tableValue">{download?.user_name}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">센서 ID</div>
                                    <div className="tableValue">{download?.sensor_id}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">센서 유형</div>
                                    <div className="tableValue">{download?.category_name}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <div className="tableLabel">센서 이름</div>
                                    <div className="tableValue">{download?.sensor_name}</div>
                                </TableTd>
                                <TableTd addClass="">
                                    <a href="">Download <img src="/assets/images/icon/fileDownload.svg"/></a>
                                </TableTd>
                            </TableTr>
                        </Fragment>
                    ))}
                </TableSection>
                {downloadApi ? <PageNation perPage={5} total={downloadApi?.data?.report_download_histories?.last_page} page={page} func={(i)=>{setPage(i)}}/> : ""}
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Index;