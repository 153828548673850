import React, {useState} from "react";
import { useNavigate } from "react-router";
import { BottomErrMsg } from "component/basic/popup";
import * as fatchSet from "../../api/api";
import useGet from "../../api/useGet";

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const data = useGet({url:`/login/image`});
    const loginImageData = data?.data?.login_image.replace(/ /g, '%20')

    const handleLogin = async () => {
        const formdata = new FormData();
        formdata.append("login_id", email);
        formdata.append("password", password);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formdata,
            url: "/login",
            loginType: "login",
            success: (data) => {
                localStorage.setItem("token", data?.data?.token?.access_token);
                localStorage.setItem("refresh_token", data?.data?.token?.refresh_token);

                if (localStorage.getItem("token")) {
                    navigate("/insight");
                    window.location.reload();
                }
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    return (
        <div className="loginContainer">
            {loginImageData && (
                <div className="loginBox" style={{ background: `url("${loginImageData}") lightgray no-repeat center / cover`}}>
                    <div className="loginLeftBox">
                        <div className="loginItem">
                            <div className="loginLogo">
                                <img src={"/assets/images/logo.png"}/>
                            </div>
                            <div className="loginInput">
                                <input type="text" name="email" placeholder="아이디를 입력하세요." onChange={(e) => { setEmail(e.target.value)}}/>
                                <input type="password" name="password" placeholder="비밀번호를 입력하세요." onChange={(e) => { setPassword(e.target.value)}}/>
                            </div>
                            <button className="loginButton" onClick={() => handleLogin()}>로그인</button>
                        </div>
                    </div>
                </div>
            )}
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </div>
    );
};

export default Login;
