import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {BottomErrMsg, CustomPopup} from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import useGet from "../../api/useGet";
import PageNation from "../../component/basic/pageNation";
import {ChkBox} from "../../component/basic/formItems";
import {TableSection, TableTd, TableTr} from "../../component/basic";

const Index = (props) => {
    let navigate = useNavigate();

    const id = useParams().type;

    const [page, setPage] =  useState(1);
    const [mouseOver, setMouseOver] =  useState('');
    const [search, setSearch] =  useState('');
    const [order, setOrder] =  useState('DESC');
    const [checkItems, setCheckItems] = useState([]);
    const [menus, setMenus] = useState([]);

    const [customPopupData, setCustomPopupData] =  useState(null);

    const sensorApi = useGet({url:`/sensor?page=${page}&data_id=${id === 'tree' ? 1 : 2}&search=${search}&order=${order}`, loginType:"login"});

    useEffect(() => {
        if (sensorApi) {
            const newMenus = [{style:{minWidth:"5%",width:"5%"}, contents:(<ChkBox
                    addClass="cartAll"
                    func={(e) => handleAllCheck(e.target.checked)}
                    checkedType={checkItems?.length === sensorApi?.data?.sensors?.data?.length && sensorApi?.data?.sensors?.data?.length > 0}
                />)},
                {style:{minWidth:"7%",width:"7%"}, contents:`센서 ID`},
                {style:{minWidth:"7%",width:"7%"}, contents:`센서 유형`},
                {style:{minWidth:"10%",width:"10%"}, contents:`센서 이름`},
                {style:{minWidth:"10%",width:"10%"}, contents:`센서 배터리`},
                {style:{minWidth:"10%",width:"10%"}, contents:`센서 최근 계측시간`},
                {style:{minWidth:"10%",width:"10%"}, contents:`센서 상태`}];

            sensorApi?.data?.sensors?.data?.length > 0 && sensorApi?.data?.sensors?.data[0]?.sensor_data_details?.map((sensor) => {
                newMenus.push({style:{minWidth: 36 / sensorApi?.data?.sensors?.data[0]?.sensor_data_details?.length + "%",width: 36 / sensorApi?.data?.sensors?.data[0]?.sensor_data_details?.length + "%"}, contents:sensor?.name})
            })
            newMenus.push({style:{minWidth:"5%",width:"5%"}, contents:``})

            setMenus(newMenus)
        }
    }, [sensorApi]);

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
            const itemArr = [];
            sensorApi?.data?.sensors?.data?.map((sensor) => {
                itemArr.push(sensor)
            })
            setCheckItems(itemArr);
        }
        else {
            setCheckItems([]);
        }
    }

    // 체크박스 선택
    const handleSingleCheck = (checked, item) => {
        let oldData = [...checkItems];
        if(checked){
            oldData.push(item);
            setCheckItems(oldData);
        } else {
            setCheckItems(checkItems.filter((el) => el.id !== item.id));
        }
    };

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <LeftMenu headTitle={id === 'tree' ? 'Tree Health' : 'Soil Condition'}/>
                <div className="contents">
                    <Header/>
                    <div className="contentsBox">
                        <div className="menuTitleBox">
                            <div className="title">{id === 'tree' ? 'Tree Health' : 'Soil Condition'}</div>
                        </div>
                        <div className="searchMenu">
                            <div className="searchLeftMenu">
                                <button onClick={() => { setOrder(order === 'DESC' ? 'ASC' : 'DESC') }}><img src="/assets/images/icon/sort.svg"/> {order === 'DESC' ? '최신순' : '오래된순'}</button>
                            </div>
                            <div className="searchRightMenu">
                                {checkItems?.length > 0 && <button onClick={() => navigate('/field/register')}><img src="/assets/images/icon/plus.svg"/> 리포트 다운로드</button>}
                                <div className="searchInput">
                                    <img src="/assets/images/icon/search.svg"/>
                                    <input type="text" placeholder="검색어를 입력하세요" onChange={(e) => {setSearch(e?.target?.value)}}/>
                                </div>
                            </div>
                        </div>
                        <div className="paginationTable">
                            <TableSection
                                headType={true}
                                headAddClass="pcContents"
                                th={menus}
                            >
                                {sensorApi?.data?.sensors?.data?.map((sensor, sensorKey) => (
                                    <Fragment key={sensorKey}>
                                        <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={(e) => { if (e?.target?.tagName !== 'LABEL' && e?.target?.tagName !== 'INPUT') { navigate(`/sensor/${id}/detail/${sensor?.id}`) } }} mouseEnterFunc={() => { setMouseOver(sensorKey) }} mouseLeaveFunc={() => { setMouseOver('') }}>
                                            <TableTd addClass="alignLeft" style={{ minWidth: menus[0]?.style?.width, width: menus[0]?.style?.width}}>
                                                <ChkBox
                                                    key={sensorKey}
                                                    addClass="notText"
                                                    func={(e) => handleSingleCheck(e.target.checked, sensor)}
                                                    checkedType={checkItems?.filter((el) => el.id === sensor.id)[0]}
                                                    label={""}
                                                />
                                            </TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:menus[1]?.style?.width, width:menus[1]?.style?.width }}>{sensor?.id}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:menus[2]?.style?.width, width:menus[2]?.style?.width }}>{sensor?.category}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:menus[3]?.style?.width, width:menus[3]?.style?.width }}>{sensor?.name}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:menus[4]?.style?.width, width:menus[4]?.style?.width }}>{sensor?.battery ? sensor?.battery : '-'}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:menus[5]?.style?.width, width:menus[5]?.style?.width }}>{sensor?.created_at ? sensor?.created_at : '-'}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:menus[6]?.style?.width, width:menus[6]?.style?.width }}>
                                                {sensor?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>진행중</div>)}
                                                {sensor?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>완료</div>)}
                                                {sensor?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>보류</div>)}
                                            </TableTd>
                                            {sensor?.sensor_data_details?.map((sensorDataDetail) => (
                                                <TableTd addClass="alignLeft" style={{ minWidth:menus[7]?.style?.width, width:menus[7]?.style?.width }}>{sensorDataDetail?.value ? (sensorDataDetail?.value + ' ' + sensorDataDetail?.unit) : '-'}</TableTd>
                                            ))}
                                            <TableTd addClass="alignLeft" style={{ minWidth:menus[8]?.style?.width, width:menus[8]?.style?.width }}>{mouseOver === sensorKey && (<img src="/assets/images/icon/arrow.svg"/>)}</TableTd>
                                        </TableTr>
                                        <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => { }} mouseLeaveFunc={() => { }}>
                                            <TableTd addClass="">
                                                <div className="tableLabel">센서 ID</div>
                                                <div className="tableValue">{sensor?.id}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">센서 유형</div>
                                                <div className="tableValue">{sensor?.category}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">센서 이름</div>
                                                <div className="tableValue">{sensor?.name}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">센서 배터리</div>
                                                <div className="tableValue">{sensor?.battery ? sensor?.battery : '-'}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">센서 최근 계측시간</div>
                                                <div className="tableValue">{sensor?.created_at ? sensor?.created_at : '-'}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">센서 상태</div>
                                                <div className="tableValue">
                                                    {sensor?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>진행중</div>)}
                                                    {sensor?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>완료</div>)}
                                                    {sensor?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>보류</div>)}
                                                </div>
                                            </TableTd>
                                            {sensorApi?.data?.sensors?.data?.length > 0 && sensorApi?.data?.sensors?.data[0]?.sensor_data_details?.map((sensorDetail) => (
                                                <TableTd addClass="">
                                                    <div className="tableLabel">{sensorDetail?.name}</div>
                                                    <div className="tableValue">{sensorDetail?.value ? (sensorDetail?.value + ' ' + sensorDetail?.unit) : '-'}</div>
                                                </TableTd>
                                            ))}
                                            <TableTd addClass="">
                                                <a href={`/sensor/${id}/detail/${sensor?.id}`}>View More <img src="/assets/images/icon/arrow.svg"/></a>
                                            </TableTd>
                                        </TableTr>
                                    </Fragment>
                                ))}
                            </TableSection>
                            {sensorApi ? <PageNation perPage={5} total={sensorApi?.data?.sensors?.last_page} page={page} func={(i)=>{setPage(i)}}/> : ""}
                        </div>
                    </div>
                </div>
            </ContentsSection>
            <CustomPopup data={customPopupData}>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        func={() => {setCustomPopupData(null)}}
                    />
                </BtnBox>
            </CustomPopup>
        </>
    );
};

export default Index;