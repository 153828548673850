import React, { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {CustomPopup} from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { MenuTitle } from "../../component/menu/menuTitle";
import Download from "./download/Index";
import Overall from "./overall/Index";

const Index = (props) => {
    const [searchParams] = useSearchParams();

    const [tab, setTab] =  useState(searchParams.get('tab') ? searchParams.get('tab') : 'download');
    const [customPopupData, setCustomPopupData] =  useState(null);

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <LeftMenu headTitle="Reports"/>
                <div className="contents">
                    <Header/>
                    <div className="contentsBox">
                        <MenuTitle title="Reports" tab={tab} buttons={[{name: "다운로드 내역", tab: "download"}, {name: "Overall Review", tab: "overall"}]} func={(e) => {setTab(e)}}/>
                        {tab === 'download' && <Download/>}
                        {tab === 'overall' && <Overall/>}
                    </div>
                </div>
            </ContentsSection>
            <CustomPopup data={customPopupData}>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        func={() => {setCustomPopupData(null)}}
                    />
                </BtnBox>
            </CustomPopup>
        </>
    );
};

export default Index;