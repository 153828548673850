function MenuTitle(data){
    return (
        <div className="menuTitleBox">
            <div className="title">{data?.title}</div>
            <div className="buttonBoxs">
                {data?.buttons?.map((button) => (
                    <button className={`buttonBox ${button?.tab === data?.tab ? 'active' : ''}`} onClick={() => {data?.func(button?.tab)}}>{button?.name}</button>
                ))}
            </div>
        </div>
    );
}

export {MenuTitle};