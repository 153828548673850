import React, {useEffect, useState} from "react";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {BottomErrMsg} from "component/basic/popup";
import moment from "moment/moment";
import {CustomSelect, DatePicer, InputBoxCol2, InputItemBox} from "../../component/basic/formItems";
import * as fatchSet from "../../api/api";
import {useNavigate, useParams} from "react-router-dom";
import useGet from "../../api/useGet";

const Modify = (props) => {
    let navigate = useNavigate();

    const id = useParams().id;

    const [category, setCategory] =  useState('');
    const [title, setTitle] =  useState('');
    const [region, setRegion] =  useState('');
    const [managerType, setManagerType] =  useState('');
    const [userId, setUserId] =  useState('');
    const [startDate, setStartDate] =  useState('');
    const [endDate, setEndDate] =  useState('');
    const [status, setStatus] =  useState('');
    const [dataChk, setDataChk] =  useState(false);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const employeeApi = useGet({url:`/employee`, loginType:"login"});
    const fieldApi = useGet({url:`/field/data/detail?field_data_id=${id}`, loginType:"login"});

    useEffect(() => {
        if (fieldApi) {
            setCategory(fieldApi?.data?.field_data?.category)
            setTitle(fieldApi?.data?.field_data?.title)
            setRegion(fieldApi?.data?.field_data?.region)
            setManagerType(fieldApi?.data?.field_data?.manager_type)
            setUserId(fieldApi?.data?.field_data?.user_id)
            setStartDate(fieldApi?.data?.field_data?.start_date)
            setEndDate(fieldApi?.data?.field_data?.end_date)
            setStatus(fieldApi?.data?.field_data?.status)
            setDataChk(true)
        }
    }, [fieldApi]);

    const handleModify = async () => {
        const formdata = new FormData();
        formdata.append("category", category);
        formdata.append("title", title);
        formdata.append("region", region);
        formdata.append("manager_type", managerType);
        formdata.append("user_id", userId);
        formdata.append("start_date", startDate);
        formdata.append("status", status);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formdata,
            url: "/field/data/register",
            loginType: "login",
            success: (data) => {
                setBottomMsgData({
                    text : "등록되었습니다",
                    chk : bottomMsgData.chk + 1
                });
                navigate('/field')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    const handleDelete = async () => {
        fatchSet.FatchApi({
            type:"DELETE",
            loginType:"login",
            url: `/field/data/delete?field_data_id=${id}`,
            success: (data) => {
                setBottomMsgData({
                    text : "삭제되었습니다",
                    chk : bottomMsgData.chk + 1
                });
                navigate('/field')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    return (
        <ContentsSection
            header={true}
            footer={true}
            addClass={"gBg"}
        >
            <LeftMenu headTitle={`ID <span class="green">${id}</span>`}/>
            <div className="contents">
                <Header/>
                <div className="contentsBox">
                    <div className="menuTitleBox">
                        <div className="title">ID <span className="green">{id}</span></div>
                        <div className="buttonBoxs nonMbtn">
                            <button className="dangerButton ghost" onClick={() => handleDelete()}>업무 삭제</button>
                            <button className="primaryButton ghost" onClick={() => navigate('/field')}>뒤로가기</button>
                            <button className="primaryButton" onClick={() => handleModify()}>업무 수정</button>
                        </div>
                    </div>
                    <div className="contentsItem">
                        <div className="inputBox">
                            <InputBoxCol2>
                                <CustomSelect
                                    inputType=""
                                    addClass=""
                                    inputName="업무 카테고리"
                                    placeholder="업무 카테고리를 선택하세요."
                                    value={category}
                                    func={(name, value)=>{setCategory(value)}}
                                    valKey="id"
                                    nameKey="type"
                                    options={[
                                        {id: "유지보수", type: "유지보수"},
                                        {id: "병해충 점검 및 관리", type: "병해충 점검 및 관리"},
                                        {id: "조경 관리", type: "조경 관리"},
                                        {id: "기타", type: "기타"},
                                    ]}
                                />
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="업무 명"
                                    placeholder="전달할 업무 명을 입력하세요"
                                    value={title}
                                    func={(value)=>{setTitle(value)}}
                                />
                            </InputBoxCol2>
                            <InputBoxCol2>
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="위치"
                                    placeholder="진행되어야 하는 업무 위치를 입력하세요"
                                    value={region}
                                    func={(value)=>{setRegion(value)}}
                                />
                                <CustomSelect
                                    inputType=""
                                    addClass=""
                                    inputName="업무 담당자"
                                    placeholder="업무 담당자를 선택하세요"
                                    value={managerType}
                                    func={(name, value)=>{setManagerType(value)}}
                                    valKey="id"
                                    nameKey="type"
                                    options={[
                                        {id: "outsourcing", type: "외주"},
                                        {id: "download", type: "직원"},
                                    ]}
                                />
                            </InputBoxCol2>
                            {managerType === 'download' && (
                                <CustomSelect
                                    inputType=""
                                    addClass=""
                                    inputName="업무 담당 직원"
                                    placeholder="업무 담당 직원을 선택하세요"
                                    value={userId}
                                    func={(name, value)=>{setUserId(value)}}
                                    valKey="id"
                                    nameKey="name"
                                    options={employeeApi?.data?.employees}
                                />
                            )}
                            <InputBoxCol2>
                                {startDate && (
                                    <DatePicer
                                        inputName="업무 배정일"
                                        placeholder="업무 배정일을 선택하세요"
                                        value={startDate}
                                        dataChk={dataChk}
                                        minCount={false}
                                        func={(value)=>{setStartDate(moment(value).format("YYYY-MM-DD"))}}
                                        prevMonth={(date) => {}}
                                        nextMonth={(date) => {}}
                                    />
                                )}
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="업무 마감일"
                                    placeholder="-"
                                    func={(value)=>{}}
                                    value={endDate ? endDate : '-'}
                                    disabled={true}
                                />
                            </InputBoxCol2>
                            <CustomSelect
                                inputType=""
                                addClass=""
                                inputName="업무 상태"
                                placeholder="업무 상태를 선택하세요"
                                value={status}
                                func={(name, value)=>{setStatus(value)}}
                                valKey="id"
                                nameKey="type"
                                options={[
                                    {id: "0", type: `<div class="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>진행중</div>`},
                                    {id: "1", type: `<div class="status green"><img src="/assets/images/basic/GreenDot.svg"/>완료</div>`},
                                    {id: "10", type: `<div class="status gray"><img src="/assets/images/basic/GrayDot.svg"/>보류</div>`},
                                ]}
                            />
                        </div>
                        <div className="mButtonBoxs">
                            <button className="primaryButton" onClick={() => handleModify()}>업무 수정</button>
                            <button className="primaryButton ghost" onClick={() => navigate('/field')}>뒤로가기</button>
                            <button className="dangerButton ghost" onClick={() => handleDelete()}>업무 삭제</button>
                        </div>
                    </div>
                </div>
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </ContentsSection>
    );
};

export default Modify;