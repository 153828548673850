import Login from "pages/member/Login";
import SettingAccount from "pages/settings/Account";
import EmployeeRegister from "pages/settings/employee/Register";
import EmployeeModify from "pages/settings/employee/Modify";
import Sensor from "pages/sensor/Index";
import SensorDetail from "pages/sensor/Detail";
import Field from "pages/field/Index";
import FieldRegister from "pages/field/Register";
import FieldModify from "pages/field/Modify";
import Reports from "pages/reports/Index";
import PublicOnly from "pages/private/PublicOnly";
import React from "react";
import { Navigate } from "react-router-dom";

//type
//login : 로그인 한정 페이지
// notLogin : 비로그인 한정 페이지
// null : 로그인,비로그인 무관 페이지

export const routes = [
    {
        exact: true,
        path: "/pageErr",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/pageErr/:err",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/",
        component: <Navigate to={localStorage.getItem("token") ? `/insight` : '/login'} />,
        type : null
    },
    {
        exact: true,
        path: "/login",
        component: <Login/>,
        type : "notLogin"
    },
    {
        exact: true,
        path: "/insight",
        component: <Field/>,
        type : "login"
    },
    {
        exact: true,
        path: "/sensor/:type",
        component: <Sensor/>,
        type : "login"
    },
    {
        exact: true,
        path: "/sensor/:type/detail/:id",
        component: <SensorDetail/>,
        type : "login"
    },
    {
        exact: true,
        path: "/field",
        component: <Field/>,
        type : "login"
    },
    {
        exact: true,
        path: "/field/register",
        component: <FieldRegister/>,
        type : "login"
    },
    {
        exact: true,
        path: "/field/detail/:id",
        component: <FieldModify/>,
        type : "login"
    },
    {
        exact: true,
        path: "/settings/account",
        component: <SettingAccount/>,
        type : "login"
    },
    {
        exact: true,
        path: "/settings/employee/register",
        component: <EmployeeRegister/>,
        type : "login"
    },
    {
        exact: true,
        path: "/settings/employee/detail/:id",
        component: <EmployeeModify/>,
        type : "login"
    },
    {
        exact: true,
        path: "/reports",
        component: <Reports/>,
        type : "login"
    },
];