import React, {Fragment, useState} from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {BottomErrMsg, CustomPopup} from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { MenuTitle } from "../../component/menu/menuTitle";
import moment from "moment/moment";
import useGet from "../../api/useGet";
import PageNation from "../../component/basic/pageNation";
import {TableBasicTextItem, TableSection, TableTd, TableTr} from "../../component/basic";

const Account = (props) => {
    let navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [page, setPage] =  useState(1);
    const [mouseOver, setMouseOver] =  useState('');
    const [search, setSearch] =  useState('');
    const [order, setOrder] =  useState('DESC');

    const [customPopupData, setCustomPopupData] =  useState(null);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const fieldApi = useGet({url:`/field/data?page=${page}&search=${search}&order=${order}`, loginType:"login"});

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <LeftMenu headTitle="Field Data"/>
                <div className="contents">
                    <Header/>
                    <div className="contentsBox">
                        <div className="menuTitleBox">
                            <div className="title">Field Data</div>
                        </div>
                        <div className="searchMenu">
                            <div className="searchLeftMenu">
                                <button onClick={() => navigate('/field/register')}><img src="/assets/images/icon/plus.svg"/> Task 등록</button>
                                <button onClick={() => { setOrder(order === 'DESC' ? 'ASC' : 'DESC') }}><img src="/assets/images/icon/sort.svg"/> {order === 'DESC' ? '최신순' : '오래된순'}</button>
                            </div>
                            <div className="searchRightMenu">
                                <div className="searchInput">
                                    <img src="/assets/images/icon/search.svg"/>
                                    <input type="text" placeholder="검색어를 입력하세요" onChange={(e) => {setSearch(e?.target?.value)}}/>
                                </div>
                            </div>
                        </div>
                        <div className="paginationTable">
                            <TableSection
                                headType={true}
                                headAddClass="pcContents"
                                th={[
                                    {style:{minWidth:"10%",width:"10%"}, contents:`ID`},
                                    {style:{minWidth:"10%",width:"10%"}, contents:`위치`},
                                    {style:{minWidth:"12%",width:"12%"}, contents:`업무 카테고리`},
                                    {style:{minWidth:"18%",width:"18%"}, contents:`업무 명`},
                                    {style:{minWidth:"9%",width:"9%"}, contents:`업무 담당자`},
                                    {style:{minWidth:"13%",width:"13%"}, contents:`업무 배정일`},
                                    {style:{minWidth:"13%",width:"13%"}, contents:`업무 마감일`},
                                    {style:{minWidth:"10%",width:"10%"}, contents:`업무 상태`},
                                    {style:{minWidth:"5%",width:"5%"}, contents:``}
                                ]}
                            >
                                {fieldApi?.data?.field_datas?.data?.map((field, fieldKey) => (
                                    <Fragment key={fieldKey}>
                                        <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={() => navigate(`/field/detail/${field?.id}`)} mouseEnterFunc={() => { setMouseOver(fieldKey) }} mouseLeaveFunc={() => { setMouseOver('') }}>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{field?.id}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{field?.region}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"12%", width:"12%" }}>{field?.category}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"18%", width:"18%" }}>{field?.title}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"9%", width:"9%" }}>{field?.manager_type === 'download' ? '직원' : '외주'}</TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"13%", width:"13%" }}><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{field?.start_date}</div></TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"13%", width:"13%" }}><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{field?.end_date ? field?.end_date : '-'}</div></TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>
                                                {field?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>진행중</div>)}
                                                {field?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>완료</div>)}
                                                {field?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>보류</div>)}
                                            </TableTd>
                                            <TableTd addClass="alignLeft" style={{ minWidth:"5%", width:"5%" }}>{mouseOver === fieldKey && (<img src="/assets/images/icon/arrow.svg"/>)}</TableTd>
                                        </TableTr>
                                        <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => { setMouseOver(fieldKey) }} mouseLeaveFunc={() => { setMouseOver('') }}>
                                            <TableTd addClass="">
                                                <div className="tableLabel">ID</div>
                                                <div className="tableValue">{field?.id}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">위치</div>
                                                <div className="tableValue">{field?.region}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">업무 카테고리</div>
                                                <div className="tableValue">{field?.category}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">업무 명</div>
                                                <div className="tableValue">{field?.title}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">업무 담당자</div>
                                                <div className="tableValue">{field?.manager_type === 'download' ? '직원' : '외주'}</div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">업무 배정일</div>
                                                <div className="tableValue"><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{field?.start_date}</div></div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">업무 마감일</div>
                                                <div className="tableValue"><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{field?.end_date ? field?.end_date : '-'}</div></div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <div className="tableLabel">업무 상태</div>
                                                <div className="tableValue">
                                                    {field?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>진행중</div>)}
                                                    {field?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>완료</div>)}
                                                    {field?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>보류</div>)}
                                                </div>
                                            </TableTd>
                                            <TableTd addClass="">
                                                <a href={`/field/detail/${field?.id}`}>View More <img src="/assets/images/icon/arrow.svg"/></a>
                                            </TableTd>
                                        </TableTr>
                                    </Fragment>
                                ))}
                            </TableSection>
                            {fieldApi ? <PageNation perPage={5} total={fieldApi?.data?.field_datas?.last_page} page={page} func={(i)=>{setPage(i)}}/> : ""}
                        </div>
                        <BottomErrMsg
                            text={bottomMsgData.text ? bottomMsgData.text : ""}
                            chk={bottomMsgData.chk}
                        />
                    </div>
                </div>
            </ContentsSection>
            <CustomPopup data={customPopupData}>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        func={() => {setCustomPopupData(null)}}
                    />
                </BtnBox>
            </CustomPopup>
        </>
    );
};

export default Account;