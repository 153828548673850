import useGet from "api/useGet";
import React, {Fragment, useState} from "react";
import {BottomErrMsg} from "component/basic/popup";
import * as fatchSet from "../../../api/api";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {TableSection, TableTd, TableTr} from "../../../component/basic";

const Index = (props) => {
    let navigate = useNavigate();

    const [mouseOver, setMouseOver] =  useState('');
    const [search, setSearch] =  useState('');
    const [order, setOrder] =  useState('DESC');

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const employeeApi = useGet({url:`/employee?search=${search}&order=${order}`, loginType:"login"});

    return (
        <>
            <div className="searchMenu">
                <div className="searchLeftMenu">
                    <button onClick={() => navigate('/settings/employee/register')}><img src="/assets/images/icon/plus.svg"/> 직원 등록</button>
                    <button onClick={() => { setOrder(order === 'DESC' ? 'ASC' : 'DESC') }}><img src="/assets/images/icon/sort.svg"/> {order === 'DESC' ? '최신순' : '오래된순'}</button>
                </div>
                <div className="searchRightMenu">
                    <div className="searchInput">
                        <img src="/assets/images/icon/search.svg"/>
                        <input type="text" placeholder="검색어를 입력하세요" onChange={(e) => {setSearch(e?.target?.value)}}/>
                    </div>
                </div>
            </div>
            <TableSection
                headType={true}
                headAddClass="pcContents"
                th={[
                    {style:{minWidth:"10%",width:"10%"}, contents:`ID`},
                    {style:{minWidth:"10%",width:"10%"}, contents:`직원명`},
                    {style:{minWidth:"12%",width:"12%"}, contents:`직원 휴대폰 번호`},
                    {style:{minWidth:"18%",width:"18%"}, contents:`직원 계정 (ID)`},
                    {style:{minWidth:"9%",width:"9%"}, contents:`직원 등록일시`},
                    {style:{minWidth:"5%",width:"5%"}, contents:``}
                ]}
            >
                {employeeApi?.data?.employees?.map((employee, employeeKey) => (
                    <Fragment key={employeeKey}>
                        <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={() => navigate(`/settings/employee/detail/${employee?.id}`)} mouseEnterFunc={() => { setMouseOver(employeeKey) }} mouseLeaveFunc={() => { setMouseOver('') }}>
                            <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{employee?.id}</TableTd>
                            <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{employee?.name}</TableTd>
                            <TableTd addClass="alignLeft" style={{ minWidth:"12%", width:"12%" }}>{employee?.phone}</TableTd>
                            <TableTd addClass="alignLeft" style={{ minWidth:"18%", width:"18%" }}>{employee?.login_id}</TableTd>
                            <TableTd addClass="alignLeft" style={{ minWidth:"13%", width:"13%" }}><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{moment(employee?.created_at).format("YYYY/MM/DD")}</div></TableTd>
                            <TableTd addClass="alignLeft" style={{ minWidth:"5%", width:"5%" }}>{mouseOver === employeeKey && (<img src="/assets/images/icon/arrow.svg"/>)}</TableTd>
                        </TableTr>
                        <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => { }} mouseLeaveFunc={() => { }}>
                            <TableTd addClass="">
                                <div className="tableLabel">ID</div>
                                <div className="tableValue">{employee?.id}</div>
                            </TableTd>
                            <TableTd addClass="">
                                <div className="tableLabel">직원명</div>
                                <div className="tableValue">{employee?.name}</div>
                            </TableTd>
                            <TableTd addClass="">
                                <div className="tableLabel">직원 휴대폰 번호</div>
                                <div className="tableValue">{employee?.phone}</div>
                            </TableTd>
                            <TableTd addClass="">
                                <div className="tableLabel">직원 계정 (ID)</div>
                                <div className="tableValue">{employee?.login_id}</div>
                            </TableTd>
                            <TableTd addClass="">
                                <div className="tableLabel">직원 등록일시</div>
                                <div className="tableValue"><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{moment(employee?.created_at).format("YYYY/MM/DD")}</div></div>
                            </TableTd>
                            <TableTd addClass="">
                                <a href={`/settings/employee/detail/${employee?.id}`}>View More <img src="/assets/images/icon/arrow.svg"/></a>
                            </TableTd>
                        </TableTr>
                    </Fragment>
                ))}
            </TableSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Index;